import type { DOCS } from "@shared/types";

enum CreditCardType {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMERICAN = 'american',
  MNP = 'mnp',
  JCB = 'jcb',
  DISCOVER = 'discover',
  DINERS = 'diners'
}

enum PackagePaymentType {
  SUBSCRIPTION = 'subscription',
  CONSUMABLE = 'consumable',
  EPIN = 'epin'
}

enum TrialPackageType {
  FREE_TRIAL = 'freeTrial',
  STARTING_PRICE = 'startingPrice',
  NO = 'no'
}

export enum PaymentProvider {
  PAYPAL = 'paypal',
  CREDIT_CARD = 'creditCard',
  GOOGLE_PAY = 'googlePay',
  APPLE_PAY = 'applePay',
  STRIPE = 'stripe'
}

export type PaymentForm = {
  email?: string;
  cardExpirationDate?: string;
  cardHolder: string;
  cardCVV: string;
  cardNumber: string;
  acceptPolicy?: boolean;
}

export type PaymentElementTabs = PaymentProvider.CREDIT_CARD | PaymentProvider.PAYPAL | 'others';

export enum PaymentFormErrors {
  NO_ERROR,
  PACKAGE_LOAD_ERROR,
  STRIPE_TOKEN_ERROR,
  STRIPE_LOAD_ERROR
}

export type PaymentPageData = {
  isLoading: boolean;
  isSubmitLoading: boolean;
  isSubmitDisabled: boolean;
  selectedPackage: Record<string, any>;
  isTrialUsed: boolean;
  providerStatus: Partial<Record<PaymentProvider, boolean>>;
  paymentFormError: PaymentFormErrors,
  paymentMethod?: PaymentProvider;
  stripeToken: {
    token: string;
    initializeToken: string;
    amount: number;
    currency: string;
    expireDate: string;
    transactionId: string;
    secureHash: string;
  };
  registerOnPayment: boolean;
  bypassRegister: boolean;
  agreementData: AgreementData;
  isPolicyRequired: boolean;
  docLinks: Record<string, Record<DOCS, string>>;
  applyConditionalValue: (conditionalValue: Record<string, {
    label: string;
    value: string;
  }>, fallbackText: string) => string;
  getActiveCondition: () => string;
};


export type AgreementData = {
  company?: {
    title: string;
    address: string;
    phone: string;
    email: string;
    taxNumber: string;
    taxOffice: string;
  }
  customer?: {
    fullName: string;
    address: string;
    subscriberId: string;
  },
  product?: {
    name: string;
    detail: string;
    quantity: number;
    trialPrice: string;
    subTotal: string;
    vatTotal: string;
    includeVatTotal: string;
    pricesCurrency: string;
  }
}

export {
  CreditCardType as default,
  CreditCardType,
  PackagePaymentType,
  TrialPackageType,
};
